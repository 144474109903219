/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { FinnoScorePage, LocaleGuard } from '@composite';

export default () => (
  <LocaleGuard enabledLocales={['en']} redirectTo="/en/finnoscore/banks-middleeast">
    <Layout>
      <StaticSEO pageId="finnoscore-bankMiddleEast" />
      <FinnoScorePage />
    </Layout>
  </LocaleGuard>
);
